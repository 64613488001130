:root {
  --vbet-color: #d8288f;
  --stoiximan-color: rgba(59, 130, 246, 1);
  --1xbet-color: #4648e9;
  --uni-color: rgb(121, 240, 121);
  --unibet-color: #126e51;
  --codere-color: #126e51;
  --betfairexchange-color: #ffeb3b;
  --draftkings-color: #4e910d;
  --bwin-color: #000000;
  --paddypower-color: #3adaa7;
  --sbobet-color: #cecece;
  --betradar-color: rgb(6, 99, 187);
}

.login-content {
  margin-top: 5%;
  margin-left: 30%;
}

.login-btn {
  width: 70%;
  margin-left: 15%;
}

div.login-image {
  margin-left: 40%;
  padding-top: 5%;
}

img.login-image {
  width: 30%;
}

button.create-region-btn {
  float: right;
}
button.create-user-btn {
  float: right;
  width: 60%;
}
button.coverage-lost-btn {
}
button.match-fixtures-btn {
  width: 100%;
}

.client-provider-1 {
  min-width: 100%;
  background-color: green;
  color: yellow;
}

.client-provider-2 {
  min-width: 100%;
  background-color: rgb(143, 4, 124);
  color: #fff;
}

.client-provider-3 {
  min-width: 100%;
  background-color: rgb(4, 13, 143);
  color: #fff;
}

.feed-type-selection {
  min-width: 200px;
}

.selected-col {
  background-color: #cbffe25c;
}

.odds-modal .modal-content {
  max-width: 80vw;
  min-width: 500px;
  width: 80vw;
}

.odds-modal .modal-dialog {
  max-width: 80vw;
  margin: auto;
  min-width: 500px;
  width: min-content;
}

.participants-modal .modal-content {
  max-width: 90vw;
  min-width: 20vw;
  width: 90vw;
}

.participant-modal .modal-content {
  min-width: 20vw;
  width: 20vw;
}

.participants-modal .modal-dialog {
  max-width: 90vw;
  margin: auto;
  min-width: 90vw;
  width: min-content;
}

.markets-settlement-modal .modal-content {
  max-width: 80vw;
  min-width: 500px;
  width: 80vw;
  position: absolute;
}

.markets-settlement-modal .modal-dialog {
  max-width: 80vw;
  /* margin: auto; */
  min-width: 500px;
  /* width: min-content; */
  /* margin-top: 0px;
  top: 0px;
  position: fixed;
  margin-bottom: 0px;
  padding-top: 0px; */
}

.market-matches {
  color: rgb(255, 255, 255);
  top: -10px;
  position: relative;
  border-radius: 10px;
}

.unsettled {
  color: #ff0048c4 !important;
  display: block;
}

.settled {
  color: #049f0cd3 !important;
  display: block;
}

.vb-settlement-bg {
  background-color: #b9004e26 !important;
}

.sm-settlement-bg {
  background-color: #00066433 !important;
}

.xb-settlement-bg {
  background-color: #009fff21 !important;
}

.create-uni-fixture-form {
  display: flex;
  flex-direction: column;
}

.create-uni-fixture-modal .modal-dialog {
  width: 55vw;
  margin: 10px 25px !important;
  max-width: 55vw;
  transform: translate(0, 1%) !important;
}

.create-uni-fixture-footer {
  justify-content: right !important;
}

.create-uni-fixture-backdrop {
  opacity: 0.2 !important;
}

.delete-column {
  width: 80px;
}

.top-align {
  vertical-align: bottom !important;
}

.stats-chart {
  /* height:350px */
}

.custom-button-like {
  cursor: pointer !important;
  color: #cecece;
}

.custom-button-like:hover {
  cursor: pointer !important;
  color: #000;
}
.id-custom-cell {
  width: 150px !important;
}

.order-custom-cell {
  width: 250px !important;
}

.type-custom-cell {
  width: 250px !important;
}

.group-custom-cell {
  width: 250px !important;
}

.active-pro {
  position: fixed !important;
  max-width: 240px;
}

.active-pro > .nav-link {
  background: #ff000045 !important;
}

.swal-wide {
  width: max-content !important;
}

.event_id {
  position: relative;
  font-weight: bold;
  display: inline-block;
  padding: 2px 5px 2px 5px;
  background-color: gainsboro;
  text-align: center;
  min-width: max-content;
}

.event_region {
  position: relative;
  display: inline-block;
  padding: 2px 5px 2px 5px;
  background-color: lightskyblue;
  text-align: center;
  min-width: max-content;
}

.event_competition {
  position: relative;
  display: inline-block;
  padding: 2px 5px 2px 5px;
  background-color: paleturquoise;
  text-align: center;
  min-width: max-content;
}

.event_participants {
  position: relative;
  display: inline-block;
  padding: 2px 5px 2px 5px;
  background-color: wheat;
  text-align: center;
  min-width: max-content;
}

.pinnacleProvider {
  background: #f50;
  position: relative;
  color: #fff;
  padding: 2px 5px 2px 5px;
  min-width: 50px;
  display: inline-block;
  width: 100px;
  text-align: center;
}

.bet365Provider {
  background: #126e51;
  position: relative;
  color: #ffeb3b;
  padding: 2px 5px 2px 5px;
  min-width: 50px;
  display: inline-block;
  width: 100px;
  text-align: center;
}

.vbetProvider {
  background: var(--vbet-color);
  position: relative;
  color: #fff;
  padding: 2px 5px 2px 5px;
  min-width: 50px;
  display: inline-block;
  width: 100px;
  text-align: center;
}

.vbetamProvider {
  background: var(--vbet-color);
  position: relative;
  color: #fff;
  padding: 2px 5px 2px 5px;
  min-width: 50px;
  display: inline-block;
  width: 100px;
  text-align: center;
}

.vbetukProvider {
  background: var(--vbet-color);
  position: relative;
  color: #fff;
  padding: 2px 5px 2px 5px;
  min-width: 50px;
  display: inline-block;
  width: 100px;
  text-align: center;
}

.onexbetProvider,
[class*='1xbetProvider'],
[class^='1xbet'] {
  background: var(--1xbet-color);
  position: relative;
  color: #fff;
  padding: 2px 5px 2px 5px;
  min-width: 50px;
  display: inline-block;
  width: 100px;
  text-align: center;
}

.stoiximanProvider {
  background: rgba(59, 130, 246, 1);
  position: relative;
  color: #fff;
  padding: 2px 5px 2px 5px;
  min-width: 50px;
  display: inline-block;
  width: 100px;
  text-align: center;
}
[class='188betProvider'] {
  background: #4648e9;
  position: relative;
  color: #000000;
  padding: 2px 5px 2px 5px;
  min-width: 50px;
  display: inline-block;
  width: 100px;
  text-align: center;
}

.feedthebetProvider {
  background: #e90966;
  position: relative;
  color: #000000;
  padding: 2px 5px 2px 5px;
  min-width: 50px;
  display: inline-block;
  width: 100px;
  text-align: center;
}

.unibetProvider {
  background: #126e51;
  position: relative;
  color: #000000;
  padding: 2px 5px 2px 5px;
  min-width: 50px;
  display: inline-block;
  width: 100px;
  text-align: center;
}

.codereProvider {
  background: #126e51;
  position: relative;
  color: #fff;
  padding: 2px 5px 2px 5px;
  min-width: 50px;
  display: inline-block;
  width: 100px;
  text-align: center;
}

.betfairexchangeProvider {
  background: #ffeb3b;
  position: relative;
  color: #fff;
  padding: 2px 5px 2px 5px;
  min-width: 50px;
  display: inline-block;
  width: 100px;
  text-align: center;
}

.draftkingsProvider {
  background: #4e910d;
  position: relative;
  color: #fff;
  padding: 2px 5px 2px 5px;
  min-width: 50px;
  display: inline-block;
  width: 100px;
  text-align: center;
}

.bwinProvider {
  background: #000000;
  position: relative;
  color: #ffeb3b;
  padding: 2px 5px 2px 5px;
  min-width: 50px;
  display: inline-block;
  width: 100px;
  text-align: center;
}

.paddypowerProvider {
  background: #3adaa7;
  position: relative;
  color: #000000;
  padding: 2px 5px 2px 5px;
  min-width: 50px;
  display: inline-block;
  width: 100px;
  text-align: center;
}

.sbobetProvider {
  background: #cecece;
  position: relative;
  color: #000000;
  padding: 2px 5px 2px 5px;
  min-width: 50px;
  display: inline-block;
  width: 100px;
  text-align: center;
}

.rowCell {
  position: relative;
  min-width: 50px;
  display: inline-block;
  width: 100px;
}

.inPlayType {
  background: #65a765;
  position: relative;
  color: #fff;
  padding: 2px 5px 2px 5px;
  min-width: 50px;
  display: inline-block;
  width: 100px;
  text-align: center;
}

.preGameType {
  background: #f9675d;
  position: relative;
  color: #fff;
  padding: 2px 5px 2px 5px;
  min-width: 50px;
  display: inline-block;
  width: 100px;
  text-align: center;
}

.williamhillProvider {
  background: rgb(40, 134, 216);
  position: relative;
  color: #fff;
  padding: 2px 5px 2px 5px;
  min-width: 50px;
  display: inline-block;
  width: 100px;
  text-align: center;
}

.betanodeProvider {
  background: rgb(246, 143, 59);
  position: relative;
  color: #fff;
  padding: 2px 5px 2px 5px;
  min-width: 50px;
  display: inline-block;
  width: 100px;
  text-align: center;
}

.betfairProvider {
  background: #000;
  position: relative;
  color: #fff;
  padding: 2px 5px 2px 5px;
  min-width: 50px;
  display: inline-block;
  width: 100px;
  text-align: center;
}

.bofProvider {
  background: rgb(6, 99, 187);
  position: relative;
  color: #fff;
  padding: 2px 5px 2px 5px;
  min-width: 50px;
  display: inline-block;
  width: 100px;
  text-align: center;
}

.betradarProvider {
  background: rgb(6, 99, 187);
  position: relative;
  color: #fff;
  padding: 2px 5px 2px 5px;
  min-width: 50px;
  display: inline-block;
  width: 100px;
  text-align: center;
}

.novibetProvider {
  background: #06414d;
  position: relative;
  color: #ffffff;
  padding: 2px 5px 2px 5px;
  min-width: 50px;
  display: inline-block;
  width: 100px;
  text-align: center;
}

.pinnacleCell {
  background: #f50;
  color: #fff;
}

.bet365Cell {
  background: #126e51;
  color: #ffeb3b;
}

.vbetCell {
  background: var(--vbet-color);
  color: #fff;
}

.vbetamCell {
  background: var(--vbet-color);
  color: #fff;
}

.vbetukCell {
  background: var(--vbet-color);
  color: #fff;
}

[class='1xbetCell'] {
  background: var(--1xbet-color) !important;
  color: #fff !important;
  min-width: fit-content !important;
  width: auto;
  display: table-cell !important;
  text-align: left;
}

.stoiximanCell {
  background: rgba(59, 130, 246, 1);
  color: #fff;
}

[class='188betCell'] {
  background: #4648e9 !important;
  color: #000000 !important;
  min-width: fit-content !important;
  display: table-cell;
  width: auto;
  text-align: left;
}

.feedthebetCell {
  background: #e90966;
  color: #000000;
}

.unibetCell {
  background: #126e51;
  color: #000000;
}

.codereCell {
  background: #126e51;
  color: #fff;
}

.betfairexchangeCell {
  background: #ffeb3b;
  color: #fff;
}

.draftkingsCell {
  background: #4e910d;
  color: #fff;
}

.bwinCell {
  background: #000000;
  color: #ffeb3b;
}

.paddypowerCell {
  background: #3adaa7;
  color: #000000;
}

.sbobetCell {
  background: #cecece;
  color: #000000;
}

.williamhillCell {
  background: rgb(40, 134, 216);
  color: #fff;
}

.betanodeCell {
  background: rgb(246, 143, 59);
  color: #fff;
}

.betfairCell {
  background: #000;
  color: #fff;
}

.bofCell {
  background: rgb(6, 99, 187);
  color: #fff;
}

.betradarCell {
  background: rgb(6, 99, 187);
  color: #fff;
}

.novibetCell {
  background: #06414d;
  color: #ffffff;
}

.uniProvider {
  background: rgb(121, 240, 121);
  position: relative;
  color: #fff;
  padding: 2px 5px 2px 5px;
  min-width: 50px;
  display: inline-block;
  width: 100px;
  text-align: center;
}

#provider-fixtures-holder {
  position: fixed;
  background-color: rgb(228, 255, 217);
  bottom: 10px;
  width: calc(50vw - 180px);
  max-height: 300px;
  height: 300px;
  z-index: 1000000;
  overflow: hidden;
  box-shadow: #cecece 0px 0px 20px 5px;
}

#participant-fixtures-holder {
  position: fixed;
  background-color: rgb(228, 255, 217);
  bottom: 10px;
  width: calc(50vw - 180px);
  max-height: 300px;
  height: 300px;
  z-index: 1000000;
  overflow: hidden;
  box-shadow: #cecece 0px 0px 20px 5px;
}
.participant-fixtures-holder {
  position: fixed;
  background-color: rgb(228, 255, 217);
  bottom: 10px;
  width: calc(50vw - 180px);
  max-height: 300px;
  height: 300px;
  z-index: 1000000;
  overflow: hidden;
  box-shadow: #cecece 0px 0px 20px 5px;
}

.from-provider-participant-fixtures-holder {
  position: relative;
  background-color: rgb(228, 255, 217);
  width: calc(50vw - 180px);
  max-height: 300px;
  height: 300px;
  z-index: 1000000;
  overflow: hidden;
  box-shadow: #cecece 0px 0px 20px 5px;
}

.uniIdPresent {
  color: green;
}

.modals-holder > .container-fluid {
  all: unset;
}

#results {
  position: fixed;
  background-color: #e3faff;
  bottom: 10px;
  width: calc(50vw - 180px);
  max-height: 300px;
  height: 300px;
  z-index: 1000000;
  overflow: auto; /* Changed from hidden to auto */
  box-shadow: #cecece 0px 0px 20px 5px;
}

#results-participants {
  position: fixed;
  background-color: #e3faff;
  bottom: 310px;
  width: calc(50vw - 180px);
  max-height: 300px;
  height: 300px;
  z-index: 1000000;
  overflow: auto; /* Changed from hidden to auto */
  box-shadow: #cecece 0px 0px 20px 5px;
}

.results-font {
  font-size: 12px;
}

.results-width {
  width: 100%;
}

.results-flex {
  display: flex;
  justify-content: flex-start;
  position: sticky;
  top: 0;
  background: white;
  z-index: 100;
}

.success-results {
  color: #009688;
  font-weight: bold;
  margin-right: 20px;
}

.error-results {
  color: rgba(255, 74, 86, 0.945);
  font-weight: bold;
}

.error-item,
.success-item {
  border: 1px solid rgb(204, 204, 204);
  padding: 10px;
  margin: 10px;
}

.error-item {
  background-color: rgba(255, 74, 85, 0.4);
}

.success-item {
  background-color: rgba(0, 150, 136, 0.4);
}

.results-container {
  max-height: 200px; /* Adjust this value as needed */
  overflow-y: auto;
}

.results-container > table thead {
  position: sticky;
  top: 0;
  background: white;

  z-index: 100;
}
.results-container > table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
}

.precopy-button-disabled {
  background-color: #00000030 !important;
  color: white !important;
  opacity: 1;
  border: none;
}

.precopy-button-active {
  background-color: #9ed54480 !important;
  color: white !important;
  opacity: 1;
  border: none;
}
.precopy-button-ready {
  background-color: #d5444461 !important;
  color: white !important;
  opacity: 1;
  border: none;
}

.precopy-button-disabled:hover {
  background-color: #00000060 !important;
  color: white !important;
  opacity: 1;
  border: none;
}

.precopy-button-active:hover {
  background-color: #9ed54460 !important;
  color: white !important;
  opacity: 1;
  border: none;
}
.precopy-button-ready:hover {
  background-color: #9ed54480 !important;
  color: white !important;
  opacity: 1;
  border: none;
}

.precopy-button-disabled {
  background-color: #00000030 !important;
  color: white !important;
  opacity: 1;
  border: none;
}

.results-button-active {
  background-color: #2c2f2d80 !important;
  color: white !important;
  opacity: 1;
  border: none;
}
.results-button-ready {
  background-color: #2c2f2d60 !important;
  color: white !important;
  opacity: 1;
  border: none;
}

.results-button-disabled:hover {
  background-color: #2c2f2d60 !important;
  color: white !important;
  opacity: 1;
  border: none;
}

.results-button-active:hover {
  background-color: #2c2f2d60 !important;
  color: white !important;
  opacity: 1;
  border: none;
}
.results-button-ready:hover {
  background-color: #2c2f2d80 !important;
  color: white !important;
  opacity: 1;
  border: none;
}
.copy-button-disabled {
  background-color: #00000030 !important;
  color: white !important;
  opacity: 1;
  border: none;
}

.copy-button-active {
  background-color: #1dc7ea80 !important;
  color: white !important;
  opacity: 1;
  border: none;
}
.copy-button-ready {
  background-color: #1dc7ea60 !important;
  color: white !important;
  opacity: 1;
  border: none;
}

.copy-button-disabled:hover {
  background-color: #1dc7ea60 !important;
  color: white !important;
  opacity: 1;
  border: none;
}

.copy-button-active:hover {
  background-color: #1dc7ea60 !important;
  color: white !important;
  opacity: 1;
  border: none;
}
.copy-button-ready:hover {
  background-color: #1dc7ea80 !important;
  color: white !important;
  opacity: 1;
  border: none;
}

#uni-fixtures-holder {
  position: fixed;
  background-color: #e3faff;
  bottom: 10px;
  width: calc(50vw - 180px);
  max-height: 300px;
  height: 300px;
  z-index: 1000000;
  overflow: hidden;
  box-shadow: #cecece 0px 0px 20px 5px;
}

#uni-fixtures-table td {
  font-size: 13px !important;
}

#uni-fixtures-table th {
  background-color: #f0fcff;
}

#uni-fixtures-table input {
  font-size: 13px !important;
}

#prov-fixtures-table td {
  font-size: 13px !important;
}

#prov-fixtures-table th {
  background-color: #fff1f1;
}

#prov-fixtures-table input {
  font-size: 13px !important;
}

.cell-item {
  font-size: 10px;
}

.cell-row {
  margin: auto !important;
}

.provider-fixtures-table-holder {
  padding-top: 0px !important;
}

.match-column {
  width: 40px;
}

.off-switch {
  color: #fff;
  font-weight: 600;
  border: 1px solid #ff000e;
  padding: 2px 10px 2px 0px;
  border-radius: 12px;
  background-color: #ff6e76;
  cursor: pointer;
}
.off-switch:hover {
  color: #fff;
  font-weight: 600;
  border: 1px solid #ff000e80;
  padding: 2px 10px 2px 0px;
  border-radius: 12px;
  background-color: #ff6e76;
  cursor: pointer;
}

.on-switch {
  color: #fff;
  font-weight: 600;
  border: 1px solid #15ff00;
  padding: 2px 0px 2px 10px;
  border-radius: 12px;
  background-color: #90ff6e;
  cursor: pointer;
}
.on-switch:hover {
  color: #fff;
  font-weight: 600;
  border: 1px solid #15ff0080;
  padding: 2px 0px 2px 10px;
  border-radius: 12px;
  background-color: #90ff6e;
  cursor: pointer;
}

#matched-fixtures-holder {
  position: fixed;
  background-color: #e3faff;
  bottom: 10px;
  width: calc(50vw - 180px);
  max-height: 300px;
  height: 300px;
  z-index: 1000000;
  overflow: hidden;
  box-shadow: #cecece 0px 0px 20px 5px;
}

#main-info-holder {
  display: flex;
  margin-bottom: 5px;
}

.fix-id-div {
  background-color: #cecece;
  padding-right: 5px;
  padding-left: 5px;
  min-width: 100px;
  text-align: center;
  margin-top: 2px;
}

.fix-date-div {
  padding-right: 5px;
  padding-left: 5px;
  min-width: 80px;
  text-align: center;
  margin-top: 2px;
}

.fix-hours-div {
  padding-right: 5px;
  padding-left: 5px;
  min-width: 50px;
  text-align: center;
  background-color: #757575;
  color: #fff;
}

.fix-provider-div {
  padding-right: 5px !important;
  padding-left: 5px !important;
  min-width: 70px !important;
  text-align: center !important;
}

.fix-eventId-div {
  padding-right: 5px;
  padding-left: 5px;
  min-width: 80px;
  text-align: center;
  margin-top: 2px;
}

.sport-div {
  padding-right: 5px;
  padding-left: 5px;
  min-width: 120px;
  text-align: center;
}

.region-div {
  padding-right: 5px;
  padding-left: 5px;
  min-width: 100px;
  text-align: center;
}

.comp-div {
  padding-right: 5px;
  padding-left: 5px;
  min-width: 150px;
  text-align: center;
  width: fit-content;
}

.part-div-h {
  padding-right: 5px;
  padding-left: 5px;
  min-width: 25%;
  width: max-content;
  text-align: right;
}

.part-div-a {
  padding-right: 5px;
  padding-left: 5px;
  min-width: 25%;
  width: max-content;
  text-align: left;
}

.part-seperator-div {
  padding-right: 5px;
  padding-left: 5px;
  width: max-content;
  text-align: center;
}

#fixture-row-renderer:nth-child(2n + 1) {
  background-color: #e4e4e4 !important;
}

#fixture-row-renderer:nth-child(2n) {
  background-color: rgb(240, 242, 242) !important;
}

.soccer {
  margin-left: 0px;

  background: #009688;
  color: #ffe000 !important;
  padding-left: 4px;
  padding-right: 4px;
  width: 180px;
}

.basketball {
  margin-left: 0px;

  background: #ff7a00;
  color: #ffffff !important;
  padding-left: 4px;
  padding-right: 4px;
  width: 180px;
}

.tennis {
  margin-left: 0px;

  background: #f3db05;
  color: #021549 !important;
  padding-left: 4px;
  padding-right: 4px;
  width: 180px;
}

.volleyball {
  margin-left: 0px;

  background: #b1a073;
  color: #021549 !important;
  padding-left: 4px;
  padding-right: 4px;
  width: 180px;
}

.icehockey {
  margin-left: 0px;

  background: #bbf1ff;
  color: #021549 !important;
  padding-left: 4px;
  padding-right: 4px;
  width: 180px;
}

.baseball {
  margin-left: 0px;

  background: #a15d38;
  color: #fff !important;
  padding-left: 4px;
  padding-right: 4px;
  width: 180px;
}

.tabletennis {
  margin-left: 0px;

  background: #396188;
  color: #ffffff !important;
  padding-left: 4px;
  padding-right: 4px;
  width: 180px;
}

.futsal {
  margin-left: 0px;

  background: #3e6e91;
  color: #fff !important;
  padding-left: 4px;
  padding-right: 4px;
  width: 180px;
}

.rugbyleague {
  margin-left: 0px;

  background: #3e6e91;
  color: #fff !important;
  padding-left: 4px;
  padding-right: 4px;
  width: 180px;
}

.cricket {
  margin-left: 0px;

  background: #5d6935;
  color: #fff !important;
  padding-left: 4px;
  padding-right: 4px;
  width: 180px;
}

.handball {
  margin-left: 0px;

  background: #24566f;
  color: #fff !important;
  padding-left: 4px;
  padding-right: 4px;
  width: 180px;
}

.badminton {
  margin-left: 0px;

  background: #4c7d8b;
  color: #fff !important;
  padding-left: 4px;
  padding-right: 4px;
  width: 180px;
}

.australianrules {
  margin-left: 0px;

  background: #345d31;
  color: #fff !important;
  padding-left: 4px;
  padding-right: 4px;
  width: 180px;
}

.netball {
  margin-left: 0px;

  background: #9e6900;
  color: #fff !important;
  padding-left: 4px;
  padding-right: 4px;
  width: 180px;
}

.rugbyunion {
  margin-left: 0px;

  background: #617e44;
  color: #fff !important;
  padding-left: 4px;
  padding-right: 4px;
  width: 180px;
}

.beachvolleyball {
  margin-left: 0px;

  background: #b1a073;
  color: #fff !important;
  padding-left: 4px;
  padding-right: 4px;
  width: 180px;
}

.americanfootball {
  margin-left: 0px;

  background: #60b457;
  color: #021549 !important;
  padding-left: 4px;
  padding-right: 4px;
  width: 180px;
}

.waterpolo {
  margin-left: 0px;

  background: #4576a1;
  color: #fff !important;
  padding-left: 4px;
  padding-right: 4px;
  width: 180px;
}

.sportDefault {
  margin-left: 0px;

  background: #4576a1;
  color: #fff !important;
  padding-left: 4px;
  padding-right: 4px;
  width: 180px;
}

.soccer-region {
  background: #009687a8;
}

.basketball-region {
  background: #ff7a00a8;
}

.tennis-region {
  background: #f3db05a8;
}

.volleyball-region {
  background: #b1a073a8;
}

.icehockey-region {
  background: #bbf1ffa8;
}

.baseball-region {
  background: #a15d38a8;
}

.tabletennis-region {
  background: #396188a8;
}

.futsal-region {
  background: #3e6e91a8;
}

.rugbyleague-region {
  background: #3e6e91a8;
}

.cricket-region {
  background: #5d6935a8;
}

.handball-region {
  background: #24566fa8;
}

.badminton-region {
  background: #4c7d8ba8;
}

.australianrules-region {
  background: #345d31a8;
}

.netball-region {
  background: #9e6900a8;
}

.rugbyunion-region {
  background: #617e44a8;
}

.beachvolleyball-region {
  background: #b1a073a8;
}

.americanfootball-region {
  background: #60b457a8;
}

.waterpolo-region {
  background: #4576a1a8;
}

.soccer-comp {
  background: #00968870;
}

.basketball-comp {
  background: #ff7a0070;
}

.tennis-comp {
  background: #f3db0570;
}

.volleyball-comp {
  background: #b1a07370;
}

.icehockey-comp {
  background: #bbf1ff70;
}

.baseball-comp {
  background: #a15d3870;
}

.tabletennis-comp {
  background: #39618870;
}

.futsal-comp {
  background: #3e6e9170;
}

.rugbyleague-comp {
  background: #3e6e9170;
}

.cricket-comp {
  background: #5d693570;
}

.handball-comp {
  background: #24566f70;
}

.badminton-comp {
  background: #4c7d8b70;
}

.australianrules-comp {
  background: #345d3170;
}

.netball-comp {
  background: #9e690070;
}

.rugbyunion-comp {
  background: #617e4470;
}

.beachvolleyball-comp {
  background: #b1a07370;
}

.americanfootball-comp {
  background: #60b45770;
}

.waterpolo-comp {
  background: #4576a170;
}

.react-datepicker-popper {
  width: max-content;
}

.custom-cell-home {
  background-color: #b7b7b740;
}

.custom-cell-away {
  background-color: #9d9d9d40;
}

.custom-cell-home > .text-primary {
  color: #007b1f !important;
}

.custom-cell-away > .text-danger {
  color: #7b0017 !important;
}

.custom-cell-name {
  background-color: #9d9d9d40;
}

.custom-cell-name > .text-primary {
  color: rgb(40, 134, 216) !important;
}

.custom-header-seperator {
  width: 3vw !important;
  /* border-top-style: hidden !important;
  border-left-style: solid !important;
  border-right-style: solid !important;
  border-bottom-style: hidden !important; */
  background-color: #fff !important;
}

.custom-cell-seperator {
  /* border-top-style: hidden !important;
  border-left-style: solid !important;
  border-right-style: solid !important;
  border-bottom-style: hidden !important;
  */
  background-color: #fff !important;
  display: block;
}

.conflicts-header-separator {
  width: 2vw !important;
}

.conflicts-cell-separator {
  background-color: #fff !important;
  display: block;
}

.table > thead > tr > th.custom-header-seperator {
  border-top: 1px solid #fff !important;
}

.actions > .btn-simple.btn-link.p-1.btn {
  font-size: 0.85em !important;
}

.monthSelect {
  width: inherit;
  border-radius: 5px;
  border: 1px solid #cecece;
  padding: 6px;
}

.dateSelect {
  width: inherit;
  border-radius: 5px;
  border: 1px solid #cecece;
  padding: 6px;
}

.react-datepicker__input-container > input {
  width: inherit;
  border-radius: 5px;
  border: 1px solid #cecece;
  padding: 6px;
}

.loadingSpinnerColor {
  color: #007bff;
}

.hidden {
  display: none;
}

.participants-list {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0;
  font-size: 0.7vw;
}

.custom-row-class {
  padding: 0px 0px 0px 0px !important;
}

.custom-header-class {
  width: 44vw;
  padding: 0px 0px 0px 0px !important;
}

.fa-refresh::before {
  content: '\f021';
}

#backdrop {
  background-color: #fff;
  opacity: 0.9 !important;
  width: calc(100% - 30px);
  height: 100%;
  position: absolute;
}

.create-uni-fixture-footer > button:first-child {
  margin-right: 40px !important;
}
